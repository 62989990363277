/** @jsx jsx */

import OrtYaml from '../data/orte.yaml'
import AusstellungYaml from '../data/weiterbildung.yaml'
import KategorienYaml from '../data/weiterbildungkat.yaml'
import Layout from '../components/layout'
import MySEO from '../components/seo'
import Nichts from '../components/nichts'
import SelectBox from '../components/SelectBox'

import { Container,jsx} from 'theme-ui'

const Weiterbildung = () => {
    
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const thema= AusstellungYaml.content
    
    
    if(orte&&kategorien&&thema)
    return(
        <Layout>
            <MySEO title ="Weiterbildungsangebote im Oberen Mittelrheintal" description = "Weiterbildung im Oberen Mittelrheintal und Umgebung" />
           <Container>
 
           <SelectBox kategorien={kategorien} orte={orte} thema={thema} titel="Weiterbildung" />
         </Container> 
        </Layout>
    )
    return (<Layout><Container sx={{m:3}}><Nichts message="Im Moment keine Daten zum Thema Weiterbildung vorhanden ..."/></Container></Layout>)
}
        
export default Weiterbildung